import { cp } from "fs";
import { useEffect, useState } from "react"
import styled from "styled-components";

const Timer = styled.div`
    background-color: #E96500;
    width: 100;
    color: white;
    font-family: "Gotham-Medium";
    text-align: center;
    padding: 10px;

    span{
        margin: 0px 3px;
    }

`

interface FormatTime{
    seconds: string;
}

export default function Counter(){
    const [time, setTime] = useState(282);

    const FormatTime = (seconds: number)=>{
        const minutes = Math.floor(seconds /60);
        const remainingMinutes = (seconds % 60);
        return `${minutes}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`
    }

    useEffect(() => {
        const timer = setInterval(() => {
          setTime(prevTime => {
            if (prevTime > 0) {
              return prevTime - 1;
            } else {
              clearInterval(timer);
              return 0;
            }
          });
        }, 1000);
        return () => clearInterval(timer);
      }, []);

    return(
        <Timer> Essa oferta acaba em: 
            <span>
            {FormatTime(time)} 
            </span>
            min...
        </Timer>
    )
}